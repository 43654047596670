<template>
  <div class="glucose-list-page w-100">
    <b-container fluid class="glucose-list-page__body mb-6 mt-6">
      <b-row>
        <b-col>
          <div class="card card-custom gutter-b">
            <div class="card-body mt-0">
              <h5 class="card-title">Ngưỡng Đường Huyết </h5>
              <template-table
                :column="column"
                :data="data"
                :paging="paging"
                :tableAction="false"
                :selectAction="false"
                :searchAction="false"
                :pagingAction="false"
                @search="searchRequest"
                @reset="resetRequest"
                @sortBy="sortRequest"
              >
                <template v-slot:body="{ item }">
                  <td>{{ item.glucoseBoundaryName }}</td>
                  <td style="width: 120px">
                    <span v-html="getRawGlucoseHtml(item.veryLow)"></span>
                  </td>
                  <td style="width: 120px">
                    <span v-html="getRawGlucoseHtml(item.low)"></span>
                  </td>
                  <td style="width: 120px">
                    <span v-html="getRawGlucoseHtml(item.normal)"></span>
                  </td>
                  <td style="width: 120px">
                    <span v-html="getRawGlucoseHtml(item.high)"></span>
                  </td>
                  <td style="width: 120px">
                    <span v-html="getRawGlucoseHtml(item.veryHigh)"></span>
                  </td>
                  <td style="width: 100px">
                    <a
                      v-if="isWritePermission"
                      href="javascript:;"
                      class="btn btn-icon btn-sm"
                      style="
                        background-color: #e6f2f8;
                        border: none;
                        color: #407bff;
                      "
                      title="Kích hoạt"
                      @click="showGlucoseModal(item.glucoseBoundaryKey)"
                    >
                      <span class="svg-icon">
                        <!--begin::Svg Icon-->
                        <inline-svg
                          class="svg-icon"
                          src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                        />
                        <!--end::Svg Icon-->
                      </span>
                    </a>
                  </td>
                </template>
              </template-table>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid class="glucose-list-page__body mb-6 mt-6">
      <b-row>
        <b-col>
          <div class="card card-custom gutter-b">
            <div class="card-body mt-0">
              <h5 class="card-title">Ngưỡng Đường Huyết Thai Kỳ</h5>
              <div>
                <template-table
                :column="column"
                :data="dataPregnancy"
                :paging="paging"
                :tableAction="false"
                :selectAction="false"
                :searchAction="false"
                :pagingAction="false"
                @search="searchRequest"
                @reset="resetRequest"
                @sortBy="sortRequest"
              >
                <template v-slot:body="{ item }">
                  <td>{{ item.glucoseBoundaryName }}</td>
                  <td style="width: 120px">
                    <span v-html="getRawGlucoseHtml(item.veryLow)"></span>
                  </td>
                  <td style="width: 120px">
                    <span v-html="getRawGlucoseHtml(item.low)"></span>
                  </td>
                  <td style="width: 120px">
                    <span v-html="getRawGlucoseHtml(item.normal)"></span>
                  </td>
                  <td style="width: 120px">
                    <span v-html="getRawGlucoseHtml(item.high)"></span>
                  </td>
                  <td style="width: 120px">
                    <span v-html="getRawGlucoseHtml(item.veryHigh)"></span>
                  </td>
                  <td style="width: 100px">
                    <a
                      v-if="isWritePermission"
                      href="javascript:;"
                      class="btn btn-icon btn-sm"
                      style="
                        background-color: #e6f2f8;
                        border: none;
                        color: #407bff;
                      "
                      title="Kích hoạt"
                      @click="showGlucosePregnancyModal(item.glucoseBoundaryKey)"
                    >
                      <span class="svg-icon">
                        <!--begin::Svg Icon-->
                        <inline-svg
                          class="svg-icon"
                          src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                        />
                        <!--end::Svg Icon-->
                      </span>
                    </a>
                  </td>
                </template>
              </template-table>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <glucose-popup :id="glucoseId" @reloadGlucose="loadData"></glucose-popup>
    <glucose-prenancy-popup :id="glucoseId" @reloadGlucose="loadDataPregnancy"></glucose-prenancy-popup>
  </div>
</template>

<style lang="scss" scoped>
.dropdown-form-filter {
  .dropdown-menu {
    .container {
      width: 850px;
    }
  }
}
</style>

<script>
import glucosePopup from './glucosePopup';
import glucosePrenancyPopup from './glucosePregnancyPopup.vue';

export default {
  components: {
    glucosePopup,
    glucosePrenancyPopup,
  },
  data() {
    return {
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      filter: {},
      sort: {
        by: null,
        order: null,
      },
      column: [
        {
          key: 'glucoseBoundaryName',
          label: 'Ngưỡng đường huyết',
          sortable: true,
        },
        {
          key: 'veryLow',
          label: 'Rất Thấp',
          sortable: false,
        },
        {
          key: 'low',
          label: 'Thấp',
          sortable: false,
        },
        {
          key: 'normal',
          label: 'Tốt',
          sortable: false,
        },
        {
          key: 'high',
          label: 'Cao',
          sortable: false,
        },
        {
          key: 'veryHigh',
          label: 'Rất Cao',
          sortable: false,
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
        },
      ],
      data: [],
      dataPregnancy: [],
      glucoseId: null,
      isGlucoseTypeOpts: {
        1: '<',
        2: '>',
        3: '<=',
        4: '>=',
      },
    };
  },
  computed: {
    searchParams() {
      return {
        orderBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        page: this.paging.page,
        size: this.paging.pageSize,
      };
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  watch: {
    paging: {
      handler() {
        // this.loadData();
      },
      deep: true,
    },
    sort: {
      handler() {
        this.loadData();
        this.loadDataPregnancy();
      },
      deep: true,
    },
  },
  methods: {
    pagingAction() {
      // this.loadData();
    },
    searchRequest() {
      // this.loadData();
    },
    resetRequest() {
      // this.loadData();
    },
    sortRequest(sortData) {
      this.sort = {
        by: sortData.column,
        order: sortData.order,
      };
      return;
    },
    getRawGlucoseHtml({ operator, value }) {
      return `${this.isGlucoseTypeOpts[operator]} ${value}`;
    },
    async showGlucoseModal(key) {
      this.glucoseId = key;
      this.$nextTick(() => {
        this.$bvModal.show('glucose-modal');
      });
    },
    async showGlucosePregnancyModal(key) {
      this.glucoseId = key;
      this.$nextTick(() => {
        this.$bvModal.show('glucose-pregnancy-modal');
      });
    },
    loadData() {
      this.$store.commit('context/setLoading', true);
      this.$api
        .get('Glucose', {
          params: this.searchParams,
        })
        .then(({ meta, data }) => {
          this.data = data || [];
          this.paging.total = meta.total;
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
          });
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
      return;
    },
    loadDataPregnancy() {
      this.$api
        .get('Glucose/GetGlucosePregnancy', {
          params: this.searchParams,
        })
        .then(({ meta, data }) => {
          this.dataPregnancy = data || [];
          this.paging.total = meta.total;
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
          });
        })
        .finally(() => {});
    }
  },
  mounted() {
    this.loadData();
    this.loadDataPregnancy();
  },
};
</script>
